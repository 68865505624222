import React, { useState } from 'react';

import { useInterval } from '../../hooks';

import { Wrapper } from './Scroller.style';

export default function Scroller({ messages }) {
  let [count, setCount] = useState(0);

  useInterval(() => {
    if (count + 1 === messages.length) return setCount(0)
    return setCount(count + 1);
  }, 8000);

  return (
    <Wrapper>
      {messages[count]}
    </Wrapper>
    
  )
}

import React from "react"
import { graphql } from "gatsby"

import Hero from '../components/Hero';
import Layout from '../components/Layout';
import CMSLayout from '../components/CMSLayout';
import Section from '../components/Section';
import Scroller from '../components/Scroller';

import { Heading, Subheading, Box, Testimonial, Quote, Author } from '../style/home.style';
import Sections from "../components/Sections";

export default function IndexPage({
  data,
  cmsPreview,
}) {
  const { heading, subheading, image, sections, testimonials, blur } = data.markdownRemark.frontmatter
  const Wrapper = cmsPreview ? CMSLayout : Layout;
  return (
    <Wrapper>
      <Hero image={image} blur={blur} position="50% 40%">
        <Heading>{heading}</Heading>
        <Subheading>{subheading}</Subheading>
      </Hero>
      <Sections sections={sections} notLast />
      <Section centered>
        <Box>
          <Scroller
            messages={testimonials.map(({ quote, author }) => (
              <Testimonial>
                <Quote>"{quote}"</Quote> - <Author>{author}</Author>
              </Testimonial>
            ))}
          />
        </Box>
      </Section>
    </Wrapper>
  )
}

export const pageQuery = graphql`
  query {
    markdownRemark(frontmatter: { template: { eq: "index" } }) {
      frontmatter {
        title
        heading
        subheading
        sections {
          id
          reverse
          centered
          image {
            childImageSharp {
              fixed(width: 200) {
                ...GatsbyImageSharpFixed
              }
            }
          }
          background
          content
        }
        testimonials {
          author
          quote
        }
        image {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        blur
      }
    }
  }
`
